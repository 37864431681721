import React, { Component } from 'react'
import Grid from '@material-ui/core/Grid'
import Button from '@material-ui/core/Button'
import DialogActions from '@material-ui/core/DialogActions'
import TextField from '@material-ui/core/TextField'
import ImageUpload from '../ImageUpload'
import VideoUpload from '../videoUpload'
import moment from 'moment'
import DatePicker from 'react-datepicker'
import ColorPicker from './container/colorPicker/colorPicker'
import ValidContext from './container/validContext/validContext'
import Tooltip from '@material-ui/core/Tooltip'
import ObjectOverlay from './container/objectOverlay/objectOverlay'
import ObjectGeneric from './container/objectOverlayGeneric/objectOverlayGeneric'
import CityOverlay from './container/cityOverlay/cityOverlay'
import ContractMerge from './container/contractMerge/contractMerge'
import TeamMerge from './container/teamMerge/teamMerge'
import TagInput from './container/tagInput/TagInput'
import TagInputCity from './container/tagInput/TagInputCity'
import DeleteButton from './container/deleteButton/deleteButton'
import CityImageSelect from './container/cityImageSelect/cityImageSelect'
import CityMerge from './container/cityMerge/cityMerge'
import InputCornerField from '../inputFieldcornerDropdown'
import ContractContingents from './container/contractContingents/contractContingents'
import UserManagement from './container/userManagement/userManagements'
import ArrayOrInt from './container/ArrayOrInt/arrayOrInt'

import { InputField, InputCheck, InputDropDownNew, InputFieldWithDropdown } from '../inputFields'

class EditColum extends Component {
  constructor(props) {
    super(props)

    const editRow = {}
    const editableValueCheck = []
    const labels = {}
    const changedRow = {}

    this.props.tableConfig.columns.map(row => {
      if (row.editableValueCheck) {
        editableValueCheck.push(row.name)
        Object.assign(labels, { [row.name]: row.title })
      }

      if (this.props.addRow && row.value === 'boolean') {
        Object.assign(changedRow, { [row.name]: false })
      }

      if (this.props.addRow && row.value === 'tagInput' || row.value === 'tagInputCity') {
        Object.assign(changedRow, { [row.name]: [] })
      }

      if (this.props.addRow && row.value === 'datetime' && row.toCreate !== false) {
        Object.assign(changedRow, { [row.name]: new Date() })
      }

      if (this.props.addRow && row.dropdown && row.toCreate !== false) {
        Object.assign(changedRow, { [row.name]: row.dropdownValue[0].value })
      }
      if (this.props.addRow && row.default) {
        Object.assign(changedRow, { [row.name]: row.default })
      }
      if (this.props.tableRow === undefined) return

      let rowTemp = { [row.name]: this.props.tableRow[row.name] }
      // check row of object "href"

      if (typeof this.props.tableRow[row.name] === 'object' && !Array.isArray(this.props.tableRow[row.name])) {
        if ((this.props.tableRow[row.name] !== null && this.props.tableRow[row.name].props !== null) || row.imagesUpload) {
          rowTemp = { [row.name]: this.props.tableRow[row.name].props.href }
        }
      } else {
        rowTemp = { [row.name]: this.props.tableRow[row.name] }
      }

      return Object.assign(editRow, rowTemp)
    })

    this.state = {
      editRow,
      changedRow,
      editableValueCheck: editableValueCheck,
      labels: labels
    }

    this.handleChangeCheck = this.handleChangeCheck.bind(this)
    this.tempHandleChange = this.handleChange.bind(this)
    this.handleChangeNumber = this.handleChangeNumber.bind(this)
    this.handleImageUploadPending = this.handleImageUploadPending.bind(this)
    this.handleImageUploadReset = this.handleImageUploadReset.bind(this)
    this.handleVideoUploadPending = this.handleVideoUploadPending.bind(this)
    this.handleVideoUploadReset = this.handleVideoUploadReset.bind(this)
  }

  handleChange(event) {
    const x = event.target.value
    this.setState({
      editRow: {
        ...this.state.editRow,
        [event.target.id]: x
      },
      changedRow: {
        ...this.state.changedRow,
        [event.target.id]: x
      }
    })
  }

  handleChangeNumber(event) {
    const x = event.target.value
    x.replace(',', '.')

    this.setState({
      editRow: {
        ...this.state.editRow,
        [event.target.id]: Number(x)
      },
      changedRow: {
        ...this.state.changedRow,
        [event.target.id]: Number(x)
      }
    })
  }

  handleChangeArrayOrInt = (event, id) => {
    this.setState({
      editRow: {
        ...this.state.editRow,
        [id]: event
      },
      changedRow: {
        ...this.state.changedRow,
        [id]: event
      }
    })
  }

  onChangeColorPicker = (name, colors) => {
    this.setState({
      editRow: {
        ...this.state.editRow,
        [name]: colors
      },
      changedRow: {
        ...this.state.changedRow,
        [name]: colors
      }
    })
  }

  onChangeValidContext = (name, context) => {
    this.setState({
      editRow: {
        ...this.state.editRow,
        [name]: JSON.stringify(context)
      },
      changedRow: {
        ...this.state.changedRow,
        [name]: JSON.stringify(context)
      }
    })
  }

  handleChangeCheck = (name) => (event) => {
    this.setState({
      editRow: {
        ...this.state.editRow,
        [name]: event.target.checked
      },
      changedRow: {
        ...this.state.changedRow,
        [name]: event.target.checked
      }
    })
  }

  onChangeObjectOverlay = (name, obj) => {
    this.setState({
      editRow: {
        ...this.state.editRow,
        [name]: obj
      },
      changedRow: {
        ...this.state.changedRow,
        [name]: obj
      }
    })
  }

  onChangeCityOverlay = (obj) => {
    this.setState({
      editRow: {
        ...this.state.editRow,
        ...obj
      },
      changedRow: {
        ...this.state.changedRow,
        ...obj
      }
    })
  }

  async handleRow() {
    let check = true

    if (this.state.editableValueCheck.length > 0) {
      this.state.editableValueCheck.map(n => {
        if (this.state.changedRow[n]) {
          for (let i = 0; i < this.props.tableConfig.columns.length; i++) {
            if (this.props.tableConfig.columns[i].name === n) {
              const tempEditValue = this.props.tableConfig.columns[i].editableValueCheck
              let tempEditValueText = ''
              let checkSearch = false

              for (let y = 0; y < tempEditValue.length; y++) {
                const search = this.state.editRow[n].search(tempEditValue[y])
                tempEditValueText = tempEditValue[y] + ', ' + tempEditValueText
                if (search === 0) {
                  checkSearch = true
                  y = tempEditValue.length
                }
              }

              const titel = this.props.tableConfig.columns[i].title
              const name = this.props.tableConfig.columns[i].name

              if (checkSearch) {
                this.setState({
                  labels: {
                    ...this.state.labels,
                    [name]: titel
                  }
                })
              } else {
                check = false
                const titleErr = 'Das ' + titel + ' Feld muss mit ' + tempEditValueText + ' beginnen'
                this.setState({
                  labels: {
                    ...this.state.labels,
                    [name]: titleErr
                  }
                })
              }
            }
          }
        }
        return null
      })
    }

    for (let index = 0; index < this.props.tableConfig.columns.length; index++) {
      const name = this.props.tableConfig.columns[index].name
      const row = this.state.changedRow[name]
      let changedRow
      // check changedRow of changed array´s and split the string
      if (this.props.tableConfig.columns[index].value === 'array' && row) {
        if (Array.isArray(row)) {
          changedRow = row
        } else {
          changedRow = row.split(/\s*,\s*/)
        }

        await this.setState({
          editRow: {
            ...this.state.editRow,
            [name]: changedRow
          },
          changedRow: {
            ...this.state.changedRow,
            [name]: changedRow
          }
        })
      }
    }

    if (check) {
      let filterNullJson = {}
      Object.keys(this.state.changedRow).forEach((key, index) => {
        if (this.state.changedRow[key] === 'NULL') {
          filterNullJson = { ...filterNullJson, [key]: null }
        } else {
          filterNullJson = { ...filterNullJson, [key]: this.state.changedRow[key] }
        }
      })

      this.props.handleRow({ json: filterNullJson, row: this.props.tableRow }).then(res => {
        if (res) {
          // GOOD
          this.props.handleClose()
          this.props.changeRow(({ json: filterNullJson, row: this.props.tableRow }), this.props.addRow) // reset / rerender editing table row*/
        } else {
          // BAD
        }
      })
    }
  }

  handleImageUploadPending(rawData) {
    this.setState({
      changedRow: {
        ...this.state.changedRow,
        newThumbnail: rawData
      }
    })
  }

  handleImageUploadReset() {
    this.setState({
      changedRow: {
        ...this.state.changedRow,
        newThumbnail: undefined
      }
    })
  }

  handleImageUploadPendingV2 = (rawData, name) => {
    this.setState({
      changedRow: {
        ...this.state.changedRow,
        [name]: rawData
      }
    })
  }

  handleImageUploadResetV2 = (name) => {
    this.setState({
      changedRow: {
        ...this.state.changedRow,
        [name]: undefined
      }
    })
  }

  handleImageUploadDelete = (name, connectionRowName) => {
    const changedRow = {
      ...this.state.changedRow,
      [name]: null,
      [connectionRowName]: null
    }
    this.setState({
      changedRow
    })
  }

  handleVideoUploadPending(rawData) {
    this.setState({
      changedRow: {
        ...this.state.changedRow,
        newVideoFile: rawData
      }
    })
  }

  handleVideoUploadReset() {
    this.setState({
      changedRow: {
        ...this.state.changedRow,
        newVideoFile: undefined
      }
    })
  }

  handleDateTimePicker = (name, valueOfCheck) => (event) => {
    let changeDate = moment(event).toISOString()

    if (valueOfCheck) {
      changeDate = moment(event).valueOf()
    }

    this.setState({
      editRow: {
        ...this.state.editRow,
        [name]: changeDate
      },
      changedRow: {
        ...this.state.changedRow,
        [name]: changeDate
      }
    })
  }

  addToStringArray = (string, item) => {
    const array = string.split(',')
    array.push(item)
    return array.join(',')
  }

  handleTagInputChanged = (name, add, isCreate) => (item, index) => {
    const value = isCreate ? JSON.parse(JSON.stringify(this.state.changedRow[name])) : this.state.editRow[name] !== undefined ? JSON.parse(JSON.stringify(this.state.editRow[name])) : []

    item = item.replace(/[^a-z0-9-_]/gi, '');

    if (add) {
      value.push(item)
    } else {
      value.splice(index, 1)
    }
    this.setState({
      editRow: {
        ...this.state.editRow,
        [name]: value
      },
      changedRow: {
        ...this.state.changedRow,
        [name]: value
      }
    })
    return true
  }

  render() {
    const handleChangeDropdown = name => event => {
      const value = event.target.value === 'undefined' ? undefined : event.target.value
      this.setState({
        editRow: {
          ...this.state.editRow,
          [name]: value
        },
        changedRow: {
          ...this.state.changedRow,
          [name]: value
        }
      })
    }
    return (
      <Grid container spacing={24}>
        {this.props.tableConfig.columns.map((row, index) => {
          const toCreateCeck = this.props.addRow && row.toCreate === false
          const toEditableCheck = !this.props.addRow && row.toEditable === false
          // console.log(this.state.changedRow[row.name], this.props.addRow, this.props.changeRow[row.connectionRowName], this.props.tableRow[row.connectionRowName])
          // console.log((this.props.addRow || this.props.changeRow[row.connectionRowName] === null ? null : this.props.tableRow[row.connectionRowName]))
          if (row.name === 'mediaCityUri-imgUpload') {
            // console.log(this.state.changeRow, this.props.tableRow)
          }
          if (row.disableDialog || toCreateCeck || toEditableCheck) {
            return null
          } else if (row.imagesUpload) {
            return (
              <Grid key={index} item xs={12} sm={6}>
                <ImageUpload
                  methode={row.imagesUpload.methode || 'auto'}
                  fileFormat={row.imagesUpload.fileFormat || false}
                  noImgUrl={row.imagesUpload.noImgUrl}
                  expectedWidth={row.imagesUpload.width}
                  expectedHeight={row.imagesUpload.height}
                  imageSelectCallback={this.handleImageUploadPending}
                  imagePreviewUrl={this.state.changedRow.newThumbnail || (this.props.addRow ? null : this.props.tableRow.thumbnail)}
                  imagePreviewUrlReset={this.handleImageUploadReset}
                  handleLoading={this.props.handleLoading}
                  backgroundColor={this.props.tableRow.color ? this.props.tableRow.color : null}
                />
              </Grid>
            )
          } else if (row.imagesUploadV2) {
            return (
              <Grid key={index} item xs={12} sm={6}>
                <ImageUpload
                  methode={row.imagesUploadV2.methode || 'auto'}
                  fileFormat={row.imagesUploadV2.fileFormat || false}
                  noImgUrl={row.imagesUploadV2.noImgUrl}
                  expectedWidth={row.imagesUploadV2.width}
                  expectedHeight={row.imagesUploadV2.height}
                  imageSelectCallback={this.handleImageUploadPendingV2}
                  imagePreviewUrl={this.state.changedRow[row.name] || (this.props.addRow || (this.state.changedRow[row.connectionRowName] === null) ? null : this.props.tableRow[row.connectionRowName])}
                  imagePreviewUrlReset={this.handleImageUploadResetV2}
                  handleImageUploadDelete={this.handleImageUploadDelete}
                  handleLoading={this.props.handleLoading}
                  backgroundColor={this.props.tableRow.color ? this.props.tableRow.color : null}
                  name={row.name}
                  connectionRowName={row.connectionRowName}
                />
              </Grid>
            )
          } else if (row.videoUpload) {
            return (
              <Grid key={index} item xs={12} sm={6}>
                <VideoUpload
                  label={row.title}
                  fileFormat={row.videoUpload.fileFormat || false}
                  videoSelectCallback={this.handleVideoUploadPending}
                  videoPreviewUrl={this.state.changedRow.newVideoFile || false}
                  videoPreviewUrlReset={this.handleVideoUploadReset}
                  handleLoading={this.props.handleLoading}
                  uploadProcess={this.props.uploadProcess}
                />
              </Grid>
            )
          } else if (row.dropdown) {
            return (
              <InputDropDownNew
                key={index}
                id={row.name}
                label={row.title}
                menuField={row.dropdownValue}
                value={this.props.addRow ? this.state.changedRow[row.name] : this.state.editRow[row.name]}
                handleChange={handleChangeDropdown(row.name)}
                tooltip={row.tooltip}
                setOnlyOldState={row.setOnlyOldState || false}
              />
            )
          } else if (row.inputFieldWithDropdown) {
            return (
              <InputFieldWithDropdown
                key={index}
                id={row.name}
                label={row.title}
                menuField={row.dropdownValue}
                value={this.props.addRow ? this.state.changedRow[row.name] || '' : this.state.editRow[row.name] || ''}
                handleChange={this.tempHandleChange}
                handleChangeDropdown={handleChangeDropdown(row.name)}
                tooltip={row.tooltip}
              />
            )
          } else if (!row.editableValueCheck && (row.value === undefined || row.value === 'string' || row.value === 'array' || row.value === 'number')) {
            return (
              <InputField
                key={index}
                id={row.name}
                label={row.title}
                multiline={row.multiline}
                value={this.props.addRow ? this.state.changedRow[row.name] || '' : this.state.editRow[row.name] || ''}
                activeCheck={row.editable !== false}
                handleChange={row.value === 'number' ? this.handleChangeNumber : this.tempHandleChange}
                type={row.value === 'number' ? 'number' : null}
                tooltip={row.tooltip}
              />
            )
          } else if (row.value === 'bitrateIntOrArray') {
            let value = this.props.addRow ? this.state.changedRow[row.name] || '' : this.state.editRow[row.name] || ''
            if(value && typeof value === 'string' && value.search(',') !== -1) {value = value.split(',')}
            return (
              <Grid item xs={12} sm={6} key={index}>
                <ArrayOrInt 
                  key={index}
                  id={row.name}
                  label={row.title}
                  value={value}
                  activeCheck={row.editable !== false}
                  handleChange={this.handleChangeArrayOrInt}
                  tooltip={row.tooltip}
                />
              </Grid>
            )
          } else if (row.value === 'validContext') {
            return (
              <Grid item xs={12} sm={6} key={index}>
                <ValidContext
                  row={row}
                  value={this.props.addRow ? this.state.changedRow[row.name] || JSON.stringify([]) : this.state.editRow[row.name] || JSON.stringify([])}
                  handleChange={this.onChangeValidContext}
                />
              </Grid>
            )
          } else if (row.value === 'fieldCorners') {
            return (
              <Grid item xs={12} sm={6} key={index}>
                <InputCornerField
                  key={index}
                  id={row.name}
                  label={row.title}
                  value={this.props.addRow ? this.state.changedRow[row.name] : this.state.editRow[row.name]}
                  handleChange={handleChangeDropdown(row.name)}
                  tooltip={row.tooltip}
                  disabled={this.props.tableRow.swcsID === undefined}
                  camId={this.props.tableRow.swcsID}
                />
              </Grid>
            )
          } else if (row.value === 'colorPicker') {
            return (
              <Grid item xs={12} sm={6} key={index}>
                <ColorPicker
                  row={row}
                  max={3}
                  value={this.props.addRow ? this.state.changedRow[row.name] || [] : this.state.editRow[row.name] || []}
                  handleChange={this.onChangeColorPicker}
                />
              </Grid>
            )
          } else if (row.value === 'objectOverlay') {
            return (
              <Grid item xs={12} sm={6} key={index}>
                <ObjectOverlay
                  value={this.props.addRow ? this.state.changedRow[row.name] || [] : this.state.editRow[row.name] || []}
                  row={row}
                  onChangeObjectOverlay={this.onChangeObjectOverlay}
                />
              </Grid>
            )
          } else if (row.value === 'objectGeneric') {
            const items = this.props.addRow ? this.state.changedRow[row.name] || [] : this.state.editRow[row.name] || []
            return (
              <Grid item xs={12} sm={6} key={index}>
                <ObjectGeneric
                  value={this.props.addRow ? this.state.changedRow[row.name] || [] : this.state.editRow[row.name] || []}
                  row={row}
                  addLabel={`${row.label || row.name} Hinzufügen`}
                  listLabel={`${row.name}`}
                  items={items}
                  onAddItem={this.handleTagInputChanged(row.name, true, this.props.addRow)}
                  onRemoveItem={this.handleTagInputChanged(row.name, false, this.props.addRow)}
                />
              </Grid>
            )
          } else if (row.value === 'contractUserManagement') {
            return (
              <Grid item xs={12} sm={12} key={index}>
                <UserManagement
                  value={this.props.addRow ? this.state.changedRow[row.name] || [] : this.state.editRow[row.name] || []}
                  row={row}
                  editRow={this.state.editRow}
                  addRow={this.props.addRow}
                // onChangeObjectOverlay={this.onChangeObjectOverlay}
                />
              </Grid>
            )
          } else if (row.value === "contractContingents") {
            return (<Grid item xs={12} sm={6} key={index}>
              <ContractContingents
                value={this.props.addRow ? this.state.changedRow[row.name] || [] : this.state.editRow[row.name] || []}
                row={row}
                editRow={this.state.editRow}
                onDone={this.props.handleClose}
              />
            </Grid>
            )
          }
          else if (row.value === 'contractMerge') {
            return (
              <Grid item xs={12} sm={6} key={index}>
                <ContractMerge
                  value={this.props.addRow ? this.state.changedRow[row.name] || [] : this.state.editRow[row.name] || []}
                  row={row}
                  editRow={this.state.editRow}
                  onDone={this.props.handleClose}
                />
              </Grid>
            )
          } else if (row.value === 'teamMerge') {
            return (
              <Grid item xs={12} sm={6} key={index}>
                <TeamMerge
                  value={this.props.addRow ? this.state.changedRow[row.name] || [] : this.state.editRow[row.name] || []}
                  row={row}
                  editRow={this.state.editRow}
                  onDone={this.props.handleClose}
                />
              </Grid>
            )
          } else if (row.value === 'cityMerge') {
            return (
              <Grid item xs={12} sm={6} key={index}>
                <CityMerge
                  value={this.props.addRow ? this.state.changedRow[row.name] || [] : this.state.editRow[row.name] || []}
                  row={row}
                  editRow={this.state.editRow}
                  onDone={this.props.handleClose}
                />
              </Grid>
            )
          } else if (row.value === 'tagInput') {
            const items = this.props.addRow ? this.state.changedRow[row.name] || [] : this.state.editRow[row.name] || []
            let disabled = false
            if(row.deactivateIfOtherFieldIsSet) {
              row.deactivateIfOtherFieldIsSet.map(field => {
                const items = this.props.addRow ? this.state.changedRow[field] || [] : this.state.editRow[field] || []
                if(items.length > 0) {
                  disabled = true
                }
              })
            }
            
            return (
              <Grid item xs={12} sm={6} key={index}>
                <TagInput
                  addLabel={`${row.title || row.name} Hinzufügen`}
                  listLabel={`${row.name}`}
                  placeholder={`${row.placeholder || row.name}`}
                  items={items}
                  onAddItem={this.handleTagInputChanged(row.name, true, this.props.addRow)}
                  onRemoveItem={this.handleTagInputChanged(row.name, false, this.props.addRow)}
                  tooltip={row.tooltip}
                  dropdownValue={row.dropdownValue}
                  disabled={disabled}
                />
              </Grid>
            )
          } else if (row.value === 'tagInputCity') {
            const items = this.props.addRow ? this.state.changedRow[row.name] || [] : this.state.editRow[row.name] || []
            return (
              <Grid item xs={12} sm={6} key={index}>
                <TagInputCity
                  addLabel={`${row.title || row.name} Hinzufügen`}
                  listLabel={`${row.name}`}
                  placeholder={`${row.placeholder || row.title}`}
                  items={items}
                  onAddItem={this.handleTagInputChanged(row.name, true, this.props.addRow)}
                  onRemoveItem={this.handleTagInputChanged(row.name, false, this.props.addRow)}
                  tooltip={row.tooltip}
                // dropdownValue={row.dropdownValue}
                />
              </Grid>
            )
          } else if (row.value === 'delete') {
            if (this.props.addRow) {
              // * Can't delete something that does not exists yet
              return null
            }
            const entry = this.state.editRow
            return (
              <Grid item xs={12} sm={6} key={index}>
                <DeleteButton
                  entry={entry}
                  name={entry.name}
                  onDone={this.props.handleClose}
                  onDeleteConfirmed={row.onDeleteConfirmed}
                  row={row}
                />
              </Grid>
            )
          } else if (row.value === 'cityOverlay') {
            return (
              <Grid item xs={12} sm={6} key={index}>
                <CityOverlay
                  value={this.props.addRow ? this.state.changedRow[row.name] || [] : this.state.editRow[row.name] || []}
                  row={row}
                  onChangeCityOverlay={this.onChangeCityOverlay}
                  index={index}
                />
              </Grid>
            )
          } else if (row.value === 'cityImageSelect') {
            const cityId = this.state.editRow.RowKey
            const cityName = this.state.editRow.name
            return (
              <Grid item xs={12} sm={6} key={index}>
                <CityImageSelect
                  cityId={cityId}
                  cityName={cityName}
                  editRow={this.state.editRow}
                  row={row}
                  index={index}
                />
              </Grid>
            )
          } else if (row.value === 'boolean') {
            return (
              <InputCheck
                key={index}
                label={row.name}
                value={this.props.addRow ? this.state.changedRow[row.name] : this.state.editRow[row.name]}
                handleChange={this.handleChangeCheck}
                tooltip={row.tooltip}
              />
            )
          } else if (row.value === 'datetime' || row.value === 'datetimeValueOf') {
            const changeRowDate = this.state.changedRow[row.name] ? new Date(this.state.changedRow[row.name]) : new Date()
            const editRowDate = this.state.editRow[row.name] ? new Date(this.state.editRow[row.name]) : new Date()
            const now = moment().valueOf()
            const matchPlay = moment(this.state.editRow[row.name]).valueOf()
            const yesterday = moment(this.state.editRow[row.name]).add(-1, 'days').valueOf()
            let checkHours = false
            if (row.value === 'datetimeValueOf' && now > yesterday && now < matchPlay) {
              checkHours = true
            }
            return (
              <Grid item xs={12} sm={6} key={index} style={checkHours ? { background: 'rgb(255 222 222)' } : {}}>
                <div className='textField-label'>
                  {row.title}
                  {row.tooltip ? (
                    <Tooltip disabled title={row.tooltip} placement='bottom-end'>
                      <div className='tooltip-icon'>?</div>
                    </Tooltip>
                  ) : null}
                </div>
                <DatePicker
                  // disabled={checkHours}
                  className='table-datepicker'
                  selected={this.props.addRow ? changeRowDate : editRowDate}
                  onChange={this.handleDateTimePicker(row.name, row.value === 'datetimeValueOf')}
                  showTimeSelect
                  timeFormat='HH:mm'
                  timeIntervals={row.value === 'datetimeValueOf' ? 1 : 15}
                  dateFormat='dd.MM.yyyy      HH:mm'
                  timeCaption='time'
                />
                {checkHours ? <div style={{ color: '#7d4141', fontSize: '12px', marginTop: '8px' }}>Startzeit darf nur bis maximal 24 Stunden vor Spielbeginn geändert werden. Da es sonst zu Fehler führen kann</div> : null}
              </Grid>
            )
          } else if (row.editableValueCheck) {
            return (
              <Grid item xs={12} sm={6} key={index}>
                <div className='textField-label'>
                  {this.state.labels[row.name]}
                  {row.tooltip ? (
                    <Tooltip disabled title={row.tooltip} placement='bottom-end'>
                      <div className='tooltip-icon'>?</div>
                    </Tooltip>
                  ) : null}
                </div>
                <TextField
                  className={this.state.labels[row.name] === row.title ? ('textField-table') : ('textField-table-err')}
                  id={row.name}
                  value={this.props.addRow ? this.state.changedRow[row.name] || '' : this.state.editRow[row.name] || ''}
                  onChange={this.tempHandleChange}
                  margin='none'
                />
              </Grid>
            )
          }
          return null
        })}
        <Grid className='dialog-btn'>
          <DialogActions>
            <Button onClick={() => { this.props.handleClose() }} className='btn' color='primary' variant='contained'>
              Abbrechen
            </Button>
            <Button
              onClick={this.handleRow.bind(this)}
              className='btn'
              color='primary'
              variant='contained'
              autoFocus
            >
              {this.props.addRow ? 'Hinzufügen' : 'Speichern'}
            </Button>
          </DialogActions>
        </Grid>
      </Grid>
    )
  }
}

export default EditColum
