import React, { Component } from 'react'
import Dialog from '@material-ui/core/Dialog'
import DialogContent from '@material-ui/core/DialogContent'

import { getSocialClipsTimeByCitys, getSocialClipsTimeByClubs } from '../../../api/api-v2-admin'
import { Button, CircularProgress, DialogActions, DialogTitle, Paper, Table, TableBody, TableCell, TableHead, TableRow } from '@material-ui/core'
import DatePicker from 'react-datepicker'

class SocialClipsOverlay extends Component {
  constructor (props) {
    super(props)

    this.state = {
      open: false,
      loading: true,
      regionName: undefined,
      data: [],
      date: {
        from: new Date(new Date().getFullYear(), 0, 1),
        to: new Date()
      },
      clubIds: []
    }

    this.handleClose = this.handleClose.bind(this)
  }

  handleClose () {
    this.setState({
      open: false,
      OverlaySuccess: false,
      regionName: undefined,
      data: [],
      clubIds: []
    })
    if(this.props.close) {
      this.props.close()
    }
  }

  openOverlay = (regionName, rowName, row) => {
    if(regionName && rowName && row) {
      this.setState({
        open: true,
        regionName: regionName,
        rowName: rowName,
        clubIds: row.clubIds,
        cityIds: row.cityIds
      })
      this.getData(row.clubIds, row.cityIds)
    }
  }

  getData = async (clubIds, cityIds) => {
    const { data } = this.state
    this.setState({loading: true })

    let newData = [...data]

    let body = { 
      from: new Date(this.state.date.from).toISOString(),
      to: new Date(this.state.date.to).toISOString()
    }

    if(clubIds && clubIds.length > 0) {
      await getSocialClipsTimeByClubs({ ...body, ids: clubIds  }).then(res => {
        if(res.status === 201) {
          newData = newData.concat(res.data)
        }
      })
    }

    if(cityIds && cityIds.length > 0) {
      await getSocialClipsTimeByCitys({ ...body, ids: cityIds }).then(res => {
        if(res.status === 201) {
          newData = newData.concat(res.data)
        }
      })
    }

    newData = this.filterDuplicates(newData, 'RowKey')
    this.setState({ data: newData, loading: false })
  }

  filterDuplicates(array, key) {
    const seen = new Set()
    return array.filter(item => {
        const value = item[key]
        if (seen.has(value)) {
            return false; // Duplicate found
        }
        seen.add(value);
        return true; // Unique item
    })
}

  renderDataTable = () => {
    const subRows = this.state.data.map((sub, index) => {
      return (
        <TableRow key={sub.RowKey}>
          <TableCell>{index + 1}</TableCell>
          <TableCell title={sub.RowKey}>{sub.RowKey.substring(0,7)}...</TableCell>
          <TableCell>{sub.meta.durationSecond}</TableCell>
          <TableCell style={{ textWrapMode: 'nowrap' }}>{sub.timestamp}</TableCell>
          <TableCell>{sub.url}</TableCell>
        </TableRow>
      )
    })
    return (
      <Paper style={{ overflowX: 'auto' }}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Nr.</TableCell>
              <TableCell>ID</TableCell>
              <TableCell>Duration</TableCell>
              <TableCell>Timestamp</TableCell>
              <TableCell>URL</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>{subRows}</TableBody>
        </Table>
      </Paper>
    )
  }

  handleSetFromDate = (date, state) => {
    this.setState(prevState => ({ date: { ...prevState.date, [state]: new Date(date) } }))
  }

  render () {
    if(this.state.loading) {
      return (
        <div>
          <Dialog open={this.state.open} onClose={this.state.loading ? this.handleClose : null} aria-labelledby='form-dialog-title' fullWidth>
            <DialogContent>
              <div style={{ display: 'flex', justifyContent: 'center' }} ><CircularProgress size={40} thickness={3} /></div>
            </DialogContent>
          </Dialog>
        </div>
      )
    }

    return (
      <div>
        <Dialog open={this.state.open} onClose={this.state.loading ? this.handleClose : null} aria-labelledby='form-dialog-title' fullWidth maxWidth={'xl'}>
          <DialogTitle id='alert-dialog-title'> {this.state.regionName}: {this.state.data.length} Clips</DialogTitle>
          <DialogContent style={{ padding: 0 }}>
            {this.renderDataTable()}
          </DialogContent>
          <DialogActions>
            <div style={{ width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
              <div style={{ display: 'flex', gap: '10px', alignItems: 'center' }}>
                <DatePicker dateFormat='dd.MM.yyyy' selected={this.state.date.from} onChange={(date) => this.handleSetFromDate(date, 'from')} />
                <div>bis</div>
                <DatePicker dateFormat='dd.MM.yyyy' selected={this.state.date.to} onChange={(date) => this.handleSetFromDate(date, 'to')} />
                <Button onClick={() => this.getData(this.state.clubIds, this.state.cityIds)} color='primary' autoFocus>
                  Ok
                </Button>
              </div>
              <Button onClick={this.handleClose} color='primary'>
                Zurück
              </Button>
            </div>
          </DialogActions>
        </Dialog>
      </div>
    )
  }
}

export default SocialClipsOverlay
