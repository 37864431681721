import React, { Component } from 'react'
import Menu from './components/common/menu'
import Navbar from './components/common/navbar'
import jwtDecode from 'jwt-decode'

import backstaigeImg from './style/img/backstaige.jpg'

import { firestore, auth } from './components/common/firebase'
import {setAdminTenet} from './components/api/helper'
import { Cookies } from 'react-cookie'
import { library } from '@fortawesome/fontawesome-svg-core'
import { faSignOutAlt } from '@fortawesome/free-solid-svg-icons'
import { getAuthorization } from './components/api/helper'
import { collection, getDocs } from 'firebase/firestore'
import { getContractMe } from './components/api/api-user'

library.add(faSignOutAlt)

var loginCheck

class App extends Component {
  state = {
    loged: false,
    swisscom: false,
    user: undefined,
    meme: false
  }

  /**
   *  Check User Roles and Authentication   *
   *  output: <Menu userRoles="" />
   */
  componentDidMount () {
    // firebaseInit()
    const cookies = new Cookies()
    cookies.get('soccerwatchuser')
    cookies.get('role')

    if (cookies.cookies.role === '68754582987123654-5') {
      this.setState({ swisscom: true })
    }

    auth.onAuthStateChanged(async (user) => {
      if (user) {
        const userToken = await user.getIdToken()
        const encodeUser = jwtDecode(userToken)
        this.handleLoged(encodeUser)
      }
    })
  }

  handleLoged = async (user) => {
    // check only admin or cameraController
    let checkRole = true
    let isAdmin = false
    user.role.map(role => {
      if(role === 'admin' || role === 'cameraController'|| role === 'supporter') {
        checkRole = false
        if(role === 'admin') {
          isAdmin = true
        }
      }
    })
    if(checkRole) {
      return
    }

    if(!isAdmin) {
      const res = await getContractMe()
      let enabledForContract = false
      if(res.data) {
        res.data.forEach((contract) => {
          if(contract.admintoolEnabled) {
            enabledForContract = true
          }
        })
      }
      if(!enabledForContract) {
        this.setState({ meme: true })
        return
      }
    }

    const cookies = new Cookies()
    var today = new Date()
    var expires = new Date()
    expires.setDate(today.getDate() + 14)
    const auth = await getAuthorization()
    cookies.set('soccerwatchuser', auth, { path: '/', expires: expires })
    cookies.set('soccerwatchusername', user.email, { path: '/', expires: expires })
    cookies.set('soccerwatchRun', '564287464414', { path: '/', expires: expires })
    let fullAdmin = false // different admin role for supporter with less rights
    user.role.forEach((n) => {
      if (n === 'runnoteeditor') {
        cookies.set('soccerwatchRun', '5684252478924', { path: '/', expires: expires })
      } else if (n === 'swisscom') {
        cookies.set('role', '68754582987123654-5', { path: '/', expires: expires })
        this.setState({ swisscom: true })
      } else if (n === 'admin') {
        cookies.set('DuHzzCByRa49A8fpTjqpz2gDW9Y9YTsP', '49A8fpTj', { path: '/', expires: expires })
        fullAdmin = true
      }
    })
    if(!fullAdmin){
      console.log("set call as to cameraControler")
      setAdminTenet('contractOwner')
    }
    this.setState({ loged: true, limitAdmin: !fullAdmin, user: user })
    
  };

  DEV_GET_DATA = async () => {
    const c = collection(firestore, 'Cloudlog/Team/logs')
    const data = await getDocs(c)
    console.log('Data', data.docs.map(d => d.data()))
  }

  render () {
    const nonMeme = {
      width: '100vw',
      height: '100%',
      maxWidth: '500px',
      marginLeft: 'auto',
      marginRight: 'auto',
    }

    const meme = {
      width: '100%',
      height: '100%',
      alignContent: 'center',
      background: 'rgb(0, 0, 0)',
      textAlign: 'center'
    }

    if (this.state.loged) {
      loginCheck = (
        <div className='base loggedSite'>
          <Navbar onDiscovery={this.props.onDiscovery} />
          <Menu notification={this.props.notification} swisscom={this.state.swisscom || this.state.limitAdmin} limitAdmin={this.state.limitAdmin} user={this.state.user} />
        </div>
      )
    } else {
      loginCheck = (
        <div className='base'>
          {/* <Login handleLoged={this.handleLoged} /> */}
          <div style={this.state.meme ? meme : nonMeme}
          >
            {this.state.meme ? (
            <div>
              <a href='https://backstaige.com/'> 
                <img src={backstaigeImg} alt='backstage'/>
                <h4>Bitte benutze backstaige.com, hier benötigt man die passende Berechtigung!</h4>
              </a>
              <div style={{height: '260px', paddingTop: '60px' }}>
                <aisw-user />
              </div>
            </div>
            ) : <aisw-user />}
          </div>
        </div>
      )
    }

    return <div className='base'>{loginCheck}</div>
  }
}

export default App
