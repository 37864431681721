import React, { Component } from 'react'

import { TextField, Tooltip } from '@material-ui/core'

import './arrayOrInt.css'

class ArrayOrInt extends Component {
  constructor(props) {
    super(props)
    this.state = {}
  }

  handleChangeMin = (event) => {
    const value = Number(event.target.value) || 0
    const id = String(this.props.id)

    if(value === 0) {
      let temp = undefined
      if(Array.isArray(this.props.value)) {
        temp = this.props.value[1]
      }

      return this.props.handleChange(temp, id)
    }

    if (Array.isArray(this.props.value)) {
      const newArray = [value, this.props.value[1] || 0]
      this.props.handleChange(newArray, id)
    } else {
      const newArray = [value, this.props.value || 0]
      this.props.handleChange(newArray, id)
    }
  }

  handleChangeMax = (event) => {
    const value = Number(event.target.value) || 0
    const id = String(this.props.id)
    if (Array.isArray(this.props.value)) {
      const newArray = [this.props.value[0] || 0, value]
      this.props.handleChange(newArray, id)
    } else {
      this.props.handleChange(value, id)
    }
  }

  render() {
    let valueMin = ''
    let valueMax = this.props.value
    if (Array.isArray(this.props.value)) {
      valueMin = this.props.value[0] || 0
      valueMax = this.props.value[1] || 0
    }
    
    return (
      <div>
        <div className='textField-label'>
          {this.props.label}
          {this.props.tooltip ? (
            <Tooltip disabled title={this.props.tooltip} placement="bottom-end">
              <div className="tooltip-icon">?</div>
            </Tooltip>
          ) : null}
        </div>

        <div className='arrayOrInt'>

          <div className='arrayOrIntOb'>
            {/*<span>Min</span>*/}
            <TextField
              className='textField-table'
              id={String(this.props.id)}
              label={'Min'}
              value={valueMin}
              onChange={this.handleChangeMin}
              margin='none'
              type={'number'}
              disabled={!this.props.activeCheck || false}
              onClick={this.props.onClick}
            />
          </div>

          <div className='arrayOrIntOb'>
            <TextField
              className='textField-table'
              id={String(this.props.id)}
              label={'Max'}
              value={ valueMax}
              onChange={this.handleChangeMax}
              margin='none'
              type={'number'}
              disabled={!this.props.activeCheck || false}
              onClick={this.props.onClick}
            />
          </div>
          
        </div>
      </div>
    )
  }
}

export default ArrayOrInt
