import axios from 'axios'
import discovery from '@soccerwatch/discovery'
import * as rax from 'retry-axios'

import { getAuthorization, callAs } from './helper'

rax.attach()

export const register = async (body) => {
  return axios.post(`${discovery.API_USER}/register`, JSON.stringify(body), { headers: { Authorization: await getAuthorization(), 'Content-Type': 'application/json' } })
}

export const getUserList = async () => {
  let userList = []
  await axios.get(discovery.API_USER + '/intern/list', { headers: { Authorization: await getAuthorization() } }).then((res) => {
    userList = res
    userList.data.map((user) => {
      if (!user.name) {
        user.name = 'N/A'
      }
      user.role = JSON.stringify(user.role)
      return user
    })
  })
  return userList
}

export const getAdditionalUserData = async (userId) => {
  return axios.get(`${discovery.API_USER}/userData/${userId}/`, { headers: { Authorization: await getAuthorization() } }).then((res) => {
    return res.data
  }).catch((err) => {
    console.error('ERROR', err)
    return {}
  })
}

export const setAdditionalUserData = async (userId, data) => {
  return axios.post(`${discovery.API_USER}/userData/${userId}/`, JSON.stringify(data), { headers: { Authorization: await getAuthorization(), 'Content-Type': 'application/json' } }).then((res) => {
    return res.data
  }).catch((err) => {
    console.error('ERROR', err)
  })
}

export const getUserLicenses = async (userId, type) => {
  return axios.get(`${discovery.API_USER}/licensOfUserByType/${userId}/${type}`, { headers: { Authorization: await getAuthorization() } }).then((res) => {
    return res.data.data
  }).catch((err) => {
    console.error(err)
    return {}
  })
}

export const getUserLicensesSub = async (userId, type, subType) => {
  return axios.get(`${discovery.API_USER}/licensOfUserBySubType/${userId}/${type}/${subType}`, { headers: { Authorization: await getAuthorization() } }).then((res) => {
    return res.data.data
  }).catch((err) => {
    console.error(err)
    return {}
  })
}

export const addUserLicense = async (userId, data) => {
  const RowKey = data.RowKey ? data.RowKey + '/' : ''
  return axios.post(`${discovery.API_USER}/license/${RowKey}${userId}/`, JSON.stringify(data), { headers: { Authorization: await getAuthorization(), 'Content-Type': 'application/json' } }).then((res) => {
    return res.data
  }).catch((err) => {
    console.error(err)
    return {}
  })
}

export const deleteUserLicense = async (userId, licenseId) => {
  return axios.delete(`${discovery.API_USER}/license/delete/${licenseId}/${userId}?callAs=admin`, { headers: { Authorization: await getAuthorization() } }).then((res) => {
    return res.data
  }).catch((err) => {
    console.error(err)
    return {}
  })
}

export const patchUser = async (update) => {
  return axios.post(discovery.API_USER + '/update/', update, { headers: { Authorization: await getAuthorization(), 'Content-Type': 'application/json' } })
}

export const login = async (user) => {
  return axios.post(discovery.API_USER + '/login', JSON.stringify(user), { headers: { Authorization: await getAuthorization(), 'Content-Type': 'application/json' } })
}

export const generateQrCode = async (id) => {
  return axios.post(discovery.API_USER + '/camera/', JSON.stringify({ id: id }), { headers: { Authorization: await getAuthorization(), 'Content-Type': 'application/json' } })
}

export const getQRCodeList = async (camId) => {
  return axios.get(`${discovery.API_USER}/camera/${camId}`, { headers: { Authorization: await getAuthorization() } }).then((res) => {
    return res.data.data
  }).catch((err) => {
    console.error(err)
    return {}
  })
}

export const deleteQrCode = async (id) => {
  return axios.delete(`${discovery.API_USER}/camera/del/${id}`, { headers: { Authorization: await getAuthorization() } }).then((res) => {
    return res.data
  }).catch((err) => {
    console.error(err)
    return {}
  })
}

export const postContract = async (body) => {
  return axios.post(discovery.API_USER + '/Contract' + callAs('admin'), JSON.stringify(body), { headers: { Authorization: await getAuthorization(), 'Content-Type': 'application/json' } })
}


export const getContractMe = async () => {
  return await axios.get(discovery.API_USER + `/Contracts/me/`, { headers: { Authorization: await getAuthorization() } })
}

export const updateLicense = async (json) => {
  return axios.post(`${discovery.API_USER}/license/${json.RowKey}/${json.PartitionKey}${callAs('admin')}`, JSON.stringify(json), { headers: { Authorization: await getAuthorization(), 'Content-Type': 'application/json' } })
}

export const createLicense = async (json) => {
  return axios.post(`${discovery.API_USER}/license/${json.PartitionKey}${callAs('admin')}`, JSON.stringify(json), { headers: { Authorization: await getAuthorization(), 'Content-Type': 'application/json' } })
}

export const deleteLicense = async (id, userId) => {
  return axios.delete(`${discovery.API_USER}/license/delete/${id}/${userId}${callAs('admin')}`, { headers: { Authorization: await getAuthorization(), 'Content-Type': 'application/json' } })
}

export const getUsersOfContract = async (contractId) => {

  const licenceRes = await axios.get(`${discovery.API_USER}/licensesOfContract/${contractId}`, { headers: { Authorization: await getAuthorization(), 'Content-Type': 'application/json' } })
  // TODO: Probably add a listTillFinished here
  const licences = licenceRes.data.data
  const neededUserIds = licences.reduce((list, l) => {
    if (!list.includes(l.PartitionKey)) {
      list.push(l.PartitionKey)
    }
    return list
  }, [])

  const users = []
  for (let i in neededUserIds) {
    const userId = neededUserIds[i]
    try {
      const userRes = await axios.get(`${discovery.API_USER}/user/${userId}`, { headers: { Authorization: await getAuthorization(), 'Content-Type': 'application/json' } })
      users.push({
        RowKey: userId,
        ...userRes.data
      })
    } catch (err) {
      console.error(`Could not load user ${userId}:`, err)
      continue
    }
  }
  users.forEach((u) => {
    u.licences = []
    licences.forEach((l) => {
      if (l.PartitionKey === u.RowKey) {
        u.licences.push(l)
      }
    })
  })
  return users
}



export const getCheckUserMail = async (mail) => {
  const res = await axios.get(discovery.API_USER + `/intern/getByMail/${mail}`, { headers: { Authorization: await getAuthorization() } })
  return res.data
}

export const postUserSubscription = async (contractId, type, userId) => {
  const body = {
    contractId: contractId,
    type: type,
    userId: userId,
    cameraId: '*',
    clubId: '*'
  }
  try {
  await axios.post(discovery.API_USER + `/addUserToContract?callAs=admin`, body, { headers: { Authorization: await getAuthorization(), 'Content-Type': 'application/json' } })
    return body
  } catch (err) {
    console.error(err)
    return undefined
  }
  
}

export const deleteUserSubscription = async (subId, userId) => {
  await axios.delete(
    discovery.API_USER + `/license/delete/${subId}/${userId}?callAs=admin`, { headers: { Authorization: await getAuthorization() } }
  )
}

export const deleteUserSubscriptionList = async (subscriptionList) => {
  try {
    for (let i = 0; i < subscriptionList.length; i++) {
      await axios.delete(
        discovery.API_USER +
        `/license/delete/${subscriptionList[i].RowKey}/${subscriptionList[i].PartitionKey}?callAs=admin`,
        { headers: { Authorization: await getAuthorization(), 'Content-Type': 'application/json' } }
      )
    }
    return true
  } catch (error) {
    console.error(error)
    return false
  }
}
